import React from 'react'
import './CategoryInfo.scss'
import ReadMoreLessSections from './ReadMoreLessSections'
import { RUMMY_LEARN } from '../../internal-links'
import { RUMMY } from '../../download-links'

const RummyPracticeInfo: React.FC = () => {
  const visible = (
    <>
      <h2 className="page-title">Play Free Rummy On Mega Rummy</h2>
      <p>
        Mega Rummy is the only place to play authentic Indian rummy card games.
        Get some experience with this Rummy online free app today. To provide
        endless fun and amusement, Mega Rummy is a perfect rummy online platform
        that lets them play against each other in either a two-player or
        six-player game.
      </p>
      <p>
        Enjoy the thrill of playing Mega Rummy free online. Mega Game Download
        Playing 13 against millions of players online is a fun way to increase
        the entertainment value of a smartphone.
      </p>

      <h3>Mega Rummy: Free Rummy Variants</h3>
      <p>
        Mega Games like Pools Rummy, Points Rummy, and Deals Rummy are widely
        enjoyed by the players. Each of these variations relies on a different
        set of skills and offers its kind of excitement. The Mega free rummy
        game offers games that can be played for free and you can win real
        money.
      </p>
      <ul>
        <li>
          <h4>Points Rummy</h4>
          <p>
            Points Rummy is a variant of Rummy that can be played in a short
            amount of time. It&apos;s simple, takes little time, and is over in
            a few rounds. It&apos;ll keep you on your toes with its frenetic
            pace. In this variation, each point is worth a fixed number of
            rupees. Points rummy requires high concentration, mental acuity, and
            physical arousal because of its rapid pace.
          </p>
        </li>
        <li>
          <h4>Pool Rummy</h4>
          <p>
            The game of Pool Rummy is reminiscent of simpler times. You remember
            when you and your pals played pool rummy at the local club. Mega
            Rummy capitalizes on this interest by releasing the game digitally.
            The game&apos;s rules are the same, but the excitement has
            increased.
          </p>
        </li>
        <li>
          <h4>Deals Rummy</h4>
          <p>
            Playing Deals Rummy is the ideal way to spend a relaxing afternoon
            or evening. Those looking for a challenge with some spare time will
            enjoy this rummy variant. This game requires the use of virtual
            chips, of course—whether you&apos;re playing on the app or the
            desktop, and it&apos;s a lot of fun. The number of deals and the
            distribution of chips in deals Rummy are set up front.
          </p>
        </li>
      </ul>

      <h3>Free Rummy Tournaments</h3>
      <p>
        Practice your game at free rummy tournaments hosted by Mega Rummy. We
        value your time and have multiple daily tournaments to accommodate your
        schedule. Rummy tournament free entry apps are happening all day, so
        don&apos;t worry if you have to miss one. It is made for players of all
        skill levels and styles of Rummy.
      </p>
      <h4>Features Of Free Games on Mega Rummy</h4>
      <ol>
        <li>Easy to play and highly engaging platform.</li>
        <li>Fun-filled rummy games.</li>
        <li>Get free chips to play the rummy games.</li>
        <li>Play at your pace and place.</li>
      </ol>
      <h3>Benefits of Playing Free Games on Mega Rummy</h3>
      <ol>
        <li>
          Enjoy the thrilling game of skill with an actual experience of the
          real money games.
        </li>
        <li>
          The platform provides a great way to relax by playing rummy online for
          free.
        </li>
        <li>
          You may choose from various games to play online Rummy for free with
          unlimited chips.
        </li>
        <li>Get 10,000 free chips to play free rummy tables.</li>
      </ol>
    </>
  )
  return (
    <div className="category-info">
      <ReadMoreLessSections
        visible={visible}
        gameName="Rummy"
        apkLink={RUMMY}
        blogLink={RUMMY_LEARN}
        blogLabel="Learn Rummy"
      />
    </div>
  )
}

export default RummyPracticeInfo
