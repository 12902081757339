import React, { useEffect, useState } from 'react'

import { Helmet } from 'react-helmet'
import Layout from '../components/layout'
import SEO from '../components/seo'
import { Breadcrumb } from '../components/Breadcrumbs/Breadcrumbs'
import PracticeRummy from '../components/Games/Cards/Rummy/PracticeRummy'
import { RUMMY_PRACTICE } from '../components/internal-links'
import { LATEST_RC } from '../components/download-links'
import {
  generateBreadcrumbsSchema,
  generateFaqSchema,
  generateOrgSchema,
  generateRummyReviewSchemaAndroid,
  generateRummyReviewSchemaIos,
  generateRummyTrailerSchema,
  generateRummyTutorialsVideoSchema,
  generateWebsiteSchema,
} from '../lib/generate-schema'
import { Faq } from '../components/FAQs/faqs-list-interface'
import { rummyOneLinksRummyPage } from '../components/one-links'
import { HOMEPAGE_BREADCRUMB_TITLE } from '../utils/constnst.utils'

const breadcrumbs: Breadcrumb[] = [
  {
    title: HOMEPAGE_BREADCRUMB_TITLE,
    url: '/',
  },
  {
    title: 'Rummy Free Practice App',
    url: RUMMY_PRACTICE,
  },
]

const RummyPractice: React.FC = () => {
  const [rummyFaqsSt, setRummyFaqsSt] = useState<Faq[]>([])

  useEffect(() => {
    import('../components/FAQs/faqs-list').then(({ rummyFaqs }) =>
      setRummyFaqsSt(rummyFaqs)
    )
  }, [])

  return (
    <Layout
      showBreadcrumbs
      breadcrumbs={breadcrumbs}
      apkLink={LATEST_RC}
      showRummyForm
      rummyOneLink={rummyOneLinksRummyPage}
      customLogo
    >
      <Helmet>
        {process.env.GATSBY_STAGING === 'true' && (
          <meta name="robots" content="noindex" />
        )}
      </Helmet>
      <SEO
        title="Free Rummy Games for Practice"
        description="Free practice rummy games on Mega Rummy App. Login and Access the Free Practice Chips to Play Free Online Rummy. Download now!"
        organizationSchema={generateOrgSchema()}
        websiteSchema={generateWebsiteSchema()}
        breadcrumbsSchema={generateBreadcrumbsSchema(breadcrumbs)}
        reviewSchemaRummyAndroid={generateRummyReviewSchemaAndroid()}
        reviewSchemaRummyIos={generateRummyReviewSchemaIos()}
        faqSchema={generateFaqSchema(rummyFaqsSt)}
        videoSchema={[
          generateRummyTrailerSchema(),
          ...generateRummyTutorialsVideoSchema(),
        ]}
      />
      <PracticeRummy />
    </Layout>
  )
}

export default RummyPractice
