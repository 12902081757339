import React, { useEffect, useState } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import '../Poker/Poker.scss'
import { Container } from 'react-bootstrap'

import Videos from '../Poker/Videos'
import GameTestimonials from '../Poker/GameTestimonials'
import OtherPracticeGames from '../Poker/OtherPracticeGames'
import DownloadBottomSheet from '../../../Download/DownloadBottomSheet'

import RummyPracticeInfo from '../../CategoryAndGamesInfo/RummyPracticeInfo'
import HomeFAQs from '../../../Index/HomeFAQs/HomeFAQs'
import useVideos from '../../videos-list'
import { Faq } from '../../../FAQs/faqs-list-interface'
import useTestimonials from '../../../Testimonials/useTestimonials'
import { LATEST_RC } from '../../../download-links'
import InfoCard from '../../CategoryAndGamesInfo/InfoCard'
import { recsPracticeGameNames } from '../Poker/useRecommendPracticeGames'
import PaymentPartners from '../../../Index/PaymentPartners/PaymentPartners'
import Features from '../../../Index/Features/Features'
import SafeAndCertified from '../../../Index/SafeAndCertified/SafeAndCertified'
import MainBannerArea from '../../../Index/MainScreen/Desktop/MainBannerArea'

const PracticeRummy: React.FC = () => {
  const data = useStaticQuery(graphql`
    {
      intro: file(relativePath: { eq: "game-banners/Rummy.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      mobBanner: file(
        relativePath: { eq: "rummy-banner/mobPracticeBanner.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      deskBanner: file(
        relativePath: { eq: "rummy-banner/deskPracticeBanner.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(width: 840, layout: CONSTRAINED)
        }
      }
    }
  `)
  const rummyBanner = [
    {
      id: 1,
      mob: data.mobBanner.childImageSharp.gatsbyImageData,
      desk: data.deskBanner.childImageSharp.gatsbyImageData,
      background: '#000000',
    },
  ]

  const [rummyFaqsSt, setRummyFaqsSt] = useState<Faq[]>([])

  useEffect(() => {
    import('../../../FAQs/faqs-list').then(({ rummyPracticeFaqs }) =>
      setRummyFaqsSt(rummyPracticeFaqs)
    )
  }, [])

  const [showDownload, setShowDownload] = useState(false)
  const hideBottomSheet = (): void => {
    setShowDownload(false)
  }

  const { rummyTestimonials } = useTestimonials()
  const { rummyVideos } = useVideos()

  return (
    <div id="poker">
      <MainBannerArea
        multipleBanner={rummyBanner}
        customClass="home-banner"
        pageName="rummy-practice"
      />
      <Videos videos={rummyVideos} />
      <Container>
        <InfoCard>
          <RummyPracticeInfo />
        </InfoCard>
      </Container>

      <PaymentPartners customClass="paymentBlock" />
      <div className="d-none d-md-block">
        <Features gameName="rummy" />
      </div>
      <SafeAndCertified hideGame />
      <GameTestimonials testimonials={rummyTestimonials} />
      <div className="faqs-wrap">
        <HomeFAQs faqs={rummyFaqsSt} />
      </div>
      <OtherPracticeGames
        gameName={recsPracticeGameNames.PracticeRummy}
        category="Cards"
      />
      <DownloadBottomSheet
        show={showDownload}
        hideBottomSheet={hideBottomSheet}
        downloadLink={LATEST_RC}
      />
    </div>
  )
}

export default PracticeRummy
